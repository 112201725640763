import { createSlice } from '@reduxjs/toolkit'
import { IGrant, ISolicitation } from '../../types/Grants'
import { IPagingResponse } from '../../types/Requests'
import {
  fetchSolicitationAsync,
  fetchSolicitationAsyncBuilder,
  fetchSolicitationGrantsAsync,
  fetchSolicitationGrantsAsyncBuilder,
  fetchSolicitationsAsync,
  fetchSolicitationsAsyncBuilder,
} from './asyncRequests'

interface SolicitationFilters {
  sort: {
    [key: string]: 1 | -1
  }
}

export interface SolicitationsInitialState {
  solicitations: {
    data: ISolicitation[]
    fetching: boolean
    error: string
    paging: IPagingResponse
  }
  solicitation: {
    data: ISolicitation
    fetching: boolean
    error: string
  }
  grants: {
    data: IGrant[]
    paging: IPagingResponse
    fetching: boolean
    error: string
  }
  filters: SolicitationFilters
}

const initialState: SolicitationsInitialState = {
  solicitations: {
    data: [] as ISolicitation[],
    fetching: false,
    error: '',
    paging: {
      totalRows: 0,
      limit: 25,
      skip: 0,
      rows: 0,
      paging: true,
    },
  },
  solicitation: {
    data: {} as ISolicitation,
    fetching: false,
    error: '',
  },
  filters: {
    sort: {
      Topics: 1,
    },
  },
  grants: {
    data: [] as IGrant[],
    fetching: false,
    error: '',
    paging: {
      totalRows: 0,
      limit: 25,
      skip: 0,
      rows: 0,
      paging: true,
    },
  },
}

const SolicitationsSlice = createSlice({
  name: 'solicitations',
  initialState,
  reducers: {
    resetSolicitation: (state) => {
      state.solicitation.data = initialState.solicitation.data
    },
    resetSolicitationError: (state) => {
      state.solicitation.error = initialState.solicitation.error
    },
    resetSolicitationsError: (state) => {
      state.solicitations.error = initialState.solicitations.error
    },
    resetGrants: (state) => {
      state.grants = initialState.grants
    },
    setPaging: (state, action) => {
      const {
        limit = state.solicitations.paging.limit,
        skip = state.solicitations.paging.skip,
        paging = state.solicitations.paging.paging,
      } = action.payload

      state.solicitations.paging = { ...state.solicitations.paging, skip, limit, paging }
    },

    setGrantsPaging: (state, action) => {
      const {
        limit = state.grants.paging.limit,
        skip = state.grants.paging.skip,
        paging = state.grants.paging.paging,
      } = action.payload

      state.grants.paging = { ...state.grants.paging, skip, limit, paging }
    },
    setSolicitationsFilter: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    fetchSolicitationAsyncBuilder(builder)
    fetchSolicitationsAsyncBuilder(builder)
    fetchSolicitationGrantsAsyncBuilder(builder)
  },
})

export const SolicitationsActions = {
  ...SolicitationsSlice.actions,
  fetchSolicitationAsync,
  fetchSolicitationsAsync,
  fetchSolicitationGrantsAsync,
}

export const SolicitationsReducer = SolicitationsSlice.reducer
