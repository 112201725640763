import { AutoAwesomeMosaic, Menu } from '@mui/icons-material'
import { Box, Button, SxProps, useTheme } from '@mui/material'
import React from 'react'

interface ViewToggleButtonsProps {
  setActiveView: Function
  activeView: 'list' | 'grid'
}

const buttonContainerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}

export const ListGridToggleButton: React.FC<ViewToggleButtonsProps> = ({
  activeView,
  setActiveView,
}) => {
  const theme = useTheme()

  const handleViewChange = (view: 'list' | 'grid') => {
    setActiveView(view)
  }

  return (
    <Box sx={buttonContainerStyles}>
      <Button
        disableElevation={true}
        variant={activeView === 'list' ? 'contained' : 'outlined'}
        startIcon={<Menu />}
        onClick={() => handleViewChange('list')}
        sx={{
          position: 'absolute',
          top: 0,
          right: '300px',
          transform: 'translateY(calc(-100% - 22px))',
          textTransform: 'capitalize',
          border: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: activeView === 'list' ? theme.palette.primary.main : 'white',
          color: activeView === 'list' ? 'white' : 'inherit',
          [theme.breakpoints.down('md')]: {
            top: '100%',
            transform: 'translateY(calc(100% - 18px))',
          },
        }}
      >
        List
      </Button>
      <Button
        variant={activeView === 'grid' ? 'contained' : 'outlined'}
        startIcon={<AutoAwesomeMosaic />}
        onClick={() => handleViewChange('grid')}
        disableElevation={true}
        sx={{
          position: 'absolute',
          top: 0,
          right: '200px',
          transform: 'translateY(calc(-100% - 22px))',
          textTransform: 'capitalize',
          zIndex: 2,
          border: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: activeView === 'grid' ? theme.palette.primary.main : 'white',
          color: activeView === 'grid' ? 'white' : 'inherit',
          [theme.breakpoints.down('md')]: {
            top: '100%',
            transform: 'translateY(calc(100% - 18px))',
          },
        }}
      >
        Board
      </Button>
    </Box>
  )
}
