import { Stack, Typography, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

interface EmptyStateProps {
  icon: any
  message: String | ReactNode
}

export const EmptyState: FC<EmptyStateProps> = ({ message, icon }) => {
  const theme = useTheme()
  return (
    <Stack alignItems="center" justifyContent="center" gap={2} my="auto">
      <img src={icon} alt="empty-icon" width={200} />
      <Typography
        color="secondary.light"
        fontSize="1rem"
        fontWeight={theme.typography.fontWeightMedium}
        textAlign="center"
        px="20rem"
      >
        {message}
      </Typography>
    </Stack>
  )
}
