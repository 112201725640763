import { CalendarMonthOutlined, LinkedIn, SaveRounded, Twitter } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { AvatarIcon, LightBulbGM, Marketing2Image, Rocket } from '../../../components/icons'
import { WelcomePageFooter } from '../WelcomePageFooter'

export const AboutUs = () => {
  const theme = useTheme()

  const bulletPoints = [
    'Using CTRL + F for keywords in large, manually compiled PDF files',
    'Receiving, skimming, and then ultimately ceasing to read bloated newsletters or listserv blasts',
    'Only reading one agency because that was the only one the team had time to learn or pursue',
  ]

  const handleChipClick = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <Box>
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        fontSize="48px"
        mb={8}
        fontWeight={theme.typography.fontWeightBold}
        fontFamily="CabinetGrotesk"
      >
        Want to know about us?
      </Typography>

      {/* Section 1 */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx={{ xs: 'auto', md: 8 }}
        mb="100px"
        ml={{ xs: 4, sm: 'auto' }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ background: theme.palette.grey[200], padding: 4 }}>
            <Typography
              variant="h4"
              gutterBottom
              fontSize="32px"
              fontWeight={theme.typography.fontWeightBold}
              fontFamily="CabinetGrotesk"
              mb={2}
            >
              Why We Made GrantMatch
            </Typography>
            <Typography variant="body1" paragraph>
              Over the years, working with client after client, the stories all started sounding the
              same. All of them had unnecessarily arduous processes to source and document which
              grants they would pursue. They mostly involved lots of tedious manual tasks like:
            </Typography>
            <List sx={{ paddingLeft: '1.5rem', marginBottom: '0.5rem' }}>
              {bulletPoints.map((text, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    paddingLeft: '0',
                    display: 'list-item',
                    listStyleType: 'disc',
                    marginBottom: '0.5rem',
                  }}
                >
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
            <Typography variant="body1" paragraph mb={1}>
              I thought this was a massive problem. There were too many good ideas, from too many
              quality teams, that just weren't getting funded.
            </Typography>
            <Typography variant="body1" paragraph mb={1}>
              So we made GrantMatch. The goal was to have the grants come to clients, not you go to
              them. Through our tool we can eliminate manual processes and start to automate the
              grant discovery process on a per technology, or project, basis. With our tool,
              companies can multiply their grant pipeline by 5-10x which increases the funded
              chances as well.
            </Typography>
            <Typography variant="body1" paragraph mb={1}>
              We couldn't be more excited to bring this much needed solution to the technology
              development community.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} textAlign="center">
            <Marketing2Image
              width="100%"
              height="100%"
              style={{ width: '100%', height: '100%', maxWidth: '400px' }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Section 2 */}
      <Grid container spacing={4} justifyContent="center" pl={4}>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            paddingX: '30px',
            paddingY: '42px',
            height: 'auto',
            maxWidth: '400px',
            backgroundColor: '#FFEE99',
            borderRadius: '1rem',
            textAlign: 'center',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Rocket sx={{ height: 'auto', width: '100%', maxWidth: '310px', mb: 2 }} />{' '}
            {/* Adjusted maxWidth */}
            <Typography
              variant="h5"
              fontWeight={theme.typography.fontWeightBold}
              fontFamily="CabinetGrotesk"
            >
              Mission
            </Typography>
            <Typography variant="body1">
              Our mission is to eliminate manual processes and guess work around finding and
              deciding which grants are best for your project.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            paddingX: '40px',
            paddingY: '20px',
            height: 'auto',
            maxWidth: '400px',
            backgroundColor: '#DFF8D5',
            borderRadius: '1rem',
            textAlign: 'center',
            ml: { xs: 0, sm: 2 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <LightBulbGM sx={{ width: '100%', height: 'auto', maxWidth: '160px', mb: 2 }} />{' '}
            {/* Adjusted maxWidth */}
            <Typography
              variant="h5"
              fontWeight={theme.typography.fontWeightBold}
              mt={4}
              fontFamily="CabinetGrotesk"
            >
              Vision
            </Typography>
            <Typography variant="body1">
              We are creating a suite of tools that will optimize productivity around the technology
              development process so that you can minimize the time spent on tedious tasks and
              maximize the effort on pursuing new funding.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Section 3 */}
      <Box sx={{ flexGrow: 1, padding: { xs: 4, md: '90px' }, mt: 4 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="center"
            height="100%"
            mb={{ xs: 2, sm: 'auto' }}
          >
            <Box sx={{ backgroundColor: '#DFF8D5', width: 'auto', borderRadius: '10px' }}>
              <img
                src={AvatarIcon}
                alt="Avatar"
                style={{ maxWidth: '100%', height: 'auto', borderRadius: '4px' }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            container
            direction="column"
            spacing={4}
            display="flex"
            justifyContent="center"
            pl={{ xs: 0, md: 2 }}
          >
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              <Grid item>
                <Chip
                  label="Stalk me on LinkedIn"
                  icon={<LinkedIn />}
                  variant="outlined"
                  sx={{
                    p: '24px 20px',
                    background: '#0076B233',
                    fontWeight: 'medium',
                  }}
                  onClick={() => handleChipClick('https://www.linkedin.com/in/bennovative/')}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Follow me on X (Twitter)"
                  icon={<Twitter />}
                  variant="outlined"
                  sx={{
                    p: '24px 20px',
                    background: theme.palette.grey[200],
                    fontWeight: 'medium',
                  }}
                  onClick={() => handleChipClick('https://twitter.com/bennovative')}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="View my resume"
                  icon={<SaveRounded />}
                  variant="outlined"
                  sx={{
                    p: '24px 20px',
                    background: theme.palette.grey[200],
                    fontWeight: 'medium',
                  }}
                  onClick={() => handleChipClick('https://bennovative.io/')}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                sx={{ fontWeight: theme.typography.fontWeightBold }}
                fontFamily="CabinetGrotesk"
              >
                About the creator
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" paragraph>
                I'm Ben Bickerstaff - the creator and owner of GrantMatch. For the past decade, I
                have been working in the technology commercialization space. Half of that has been
                as an independent consultant helping small businesses win grants and commercialize
                their tech. To date I have advised over 200 different projects, across all the major
                federal agencies such as DOE, DOD, NIH, and USDA. I'm an engineer by training and
                entrepreneur by heart. When I noticed these problems recurring over and over and
                over again, I knew I needed to make a solution. Especially because my passion lies
                in unlocking the technological potential of American small businesses so they can
                create solutions today that solve problems tomorrow.
              </Typography>
              <Typography variant="body1" paragraph>
                If you have any questions at all, don't hesitate to contact me. Feel free to email
                me directly at:{' '}
                <Link href="mailto:ben@catalyzingconcepts.com">ben@catalyzingconcepts.com</Link>. Or
                you can book a meeting with me on my calendar. I'm always happy to chat.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                startIcon={<CalendarMonthOutlined />}
                onClick={() =>
                  window.open('https://calendly.com/ben-bick/grantmatch-demo', '_blank')
                }
                variant="contained"
                color="primary"
                sx={{ borderRadius: '22px', py: '10px', textTransform: 'none' }}
              >
                Book a meeting here
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid item justifyContent="center" xs={12}>
        <WelcomePageFooter />
      </Grid>
    </Box>
  )
}
