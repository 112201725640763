import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { FC, SyntheticEvent, useState } from 'react'
import { TechnologyDetail } from '../TechnologyDetail'
import { TechnologyMetrices } from '../TechnologyMatrices'

type TabValue = 'metric' | 'settings'

export const TechnologyActivity: FC = () => {
  const theme = useTheme()

  const [selectedTab, setSelectedTab] = useState<TabValue>('metric')

  const handleTabChange = (event: SyntheticEvent, newTab: TabValue) => {
    setSelectedTab(newTab)
  }

  return (
    <>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}`, mx: 4 }}
        >
          <Tab
            value="metric"
            label={
              <Typography
                fontSize="0.875rem"
                fontWeight={theme.typography.fontWeightBold}
                textTransform="none"
              >
                Technology metrics
              </Typography>
            }
          />
          <Tab
            value="settings"
            label={
              <Typography
                fontSize="0.875rem"
                fontWeight={theme.typography.fontWeightBold}
                textTransform="capitalize"
              >
                Settings
              </Typography>
            }
          />
        </Tabs>
      </Box>

      <Box position="relative">
        {selectedTab === 'metric' && <TechnologyMetrices />}
        {selectedTab === 'settings' && <TechnologyDetail />}
      </Box>
    </>
  )
}
