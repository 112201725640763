import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  useTheme,
} from '@mui/material'
import { AxiosResponse } from 'axios'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useParams } from 'react-router-dom'
import { TechnologiesApi } from '../../../services'
import { AppDispatch } from '../../../store'
import { AuthSelector } from '../../../store/auth'
import { SnackbarActions } from '../../../store/snackbar'
import { ITechnology, TechnologiesActions } from '../../../store/technologies'

interface AddToTechnologyFormProps {
  showModal: Function
}

const footerButtonStyles: SxProps = {
  color: 'secondary.main',
  fontWeight: 500,
  fontSize: '0.875rem',
  py: 1,
  px: 2,
  textTransform: 'capitalize',
  borderRadius: '0.5rem',
  height: '45px',
}

export const AddToTechnologyForm: FC<AddToTechnologyFormProps> = ({ showModal }) => {
  const theme = useTheme()
  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [selectedTechnology, setSelectedTechnology] = useState('none')
  const [technologies, setTechnologies] = useState([] as ITechnology[])

  const userId = useSelector(AuthSelector.userId)

  const handlePopulateTechnology = useCallback(async () => {
    try {
      const req = await TechnologiesApi.fetchUserTechnologies(userId, {
        params: { $paging: false },
      })
      setTechnologies((req as AxiosResponse<any>).data)
    } catch (err: any) {
      SnackbarActions.showSnackbar({ message: err.message })
    }
  }, [userId])

  useEffect(() => {
    handlePopulateTechnology()
  }, [handlePopulateTechnology])

  const handleAddToTechnology = async () => {
    const resultAction = await dispatch(
      TechnologiesActions.createTechnologyGrantAsync({
        techId: selectedTechnology,
        body: { id: params.id },
      }),
    )

    if (TechnologiesActions.createTechnologyGrantAsync.fulfilled.match(resultAction)) {
      showModal(false)
      SnackbarActions.showSnackbar({
        message: 'Technology added to list successfully!',
        options: { variant: 'success' },
      })
    }
  }

  return (
    <Box>
      <Box my={4}>
        <FormControl fullWidth>
          <InputLabel id="label">Technology</InputLabel>
          <Select
            labelId="label"
            label="Technology"
            value={selectedTechnology}
            onChange={(e) => setSelectedTechnology(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 600,
                  [theme.breakpoints.down('sm')]: {
                    maxHeight: 400,
                  },
                },
              },
            }}
          >
            <MenuItem value="none">Select Technology</MenuItem>
            {technologies.map(({ title, _id }) => (
              <MenuItem key={_id} value={_id} disableTouchRipple>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Stack justifyContent="flex-end" direction="row" gap="1rem">
        <Button
          sx={{ ...footerButtonStyles, background: theme.palette.grey[200] }}
          onClick={() => showModal(false)}
        >
          Cancel
        </Button>

        <Button
          sx={{ ...footerButtonStyles, background: theme.palette.grey[200] }}
          disabled={!selectedTechnology || selectedTechnology === 'none'}
          onClick={handleAddToTechnology}
        >
          Add to technology
        </Button>
      </Stack>
    </Box>
  )
}
