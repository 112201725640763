import { createSlice } from '@reduxjs/toolkit'
import { fetchTechnologyMatricesAsync, fetchTechnologyMatricesAsyncBuilder } from './asyncRequest'

export interface IMatrices {
  applied: number
  won: number
  loss: number
  agencies: Record<string, number>
}

export interface MatricesInitialState {
  data: IMatrices
  loading: boolean
  error: boolean
}

const initialState: MatricesInitialState = {
  data: {
    applied: 0,
    won: 0,
    loss: 0,
    agencies: {},
  },
  loading: false,
  error: false,
}

const MatricesSlice = createSlice({
  name: 'matrices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchTechnologyMatricesAsyncBuilder(builder)
  },
})
export const MatricesActions = {
  ...MatricesSlice.actions,
  fetchTechnologyMatricesAsync,
}

export const MatricesReducer = MatricesSlice.reducer
