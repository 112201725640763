import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
import { IGrant, ISolicitation } from '../../types/Grants'
import { convertPhase } from '../../utils'
import { RootState } from '../store'

const getGrants = (state: RootState) => state.grants.data || []
const getSubmitting = (state: RootState) => state.grants.submitting
const getFilters = (state: RootState) => state.grants.filters
const getSelectedAgencies = (state: RootState) => state.grants.filters.agencies
const getSelectedPhases = (state: RootState) => state.grants.filters.phases
const getSelectedStatuses = (state: RootState) => state.grants.filters.statuses
const getStartDate = (state: RootState) => state.grants.filters.dates.startDate
const getEndDate = (state: RootState) => state.grants.filters.dates.endDate
const getPaging = (state: RootState) => state.grants.paging
const error = (state: RootState) => state.grants.error

const getGrantById = createSelector(
  getGrants,
  (_: any, id: string) => id,
  (grants: any[], id) => {
    return grants.find((x) => x.solicitation_number === id)
  },
)

const makeGrants = createSelector(getGrants, (grants) => {
  return grants.map((grant) => {
    const { solicitation = {} } = grant

    const {
      agency = '',
      close_date = '',
      solicitation_title: solicitationTitle = 'N/A',
      solicitation_number: solicitationNumber = '',
      phase: grantPhase = '',
    } = solicitation as ISolicitation

    const dueDate = close_date ? moment.utc(close_date).format('MMMM DD, YYYY') : 'N/A'
    const phaseValue = convertPhase(grantPhase)
    return {
      ...grant,
      solicitationTitle: solicitationTitle,
      agency: agency,
      dueDate: dueDate,
      phase: phaseValue,
      solicitationNumber: solicitationNumber,
      grantId: grant.topicId || '',
    }
  })
})
const makeGrant = createSelector(
  makeGrants,
  (_: any, id: string) => id,
  (grants, id) => {
    const grant: IGrant = grants.find((g) => g.topicId === id) || ({} as any)
    const description =
      (grant.description && grant.description.replace(/^[\s\r\n]+|[\s\r\n]+$/g, '')) || ''

    return { ...grant, description }
  },
)

const makeGrantsCount = createSelector(getGrants, (grants) => {
  const _grants = grants || []
  return _grants.length
})

const makeSolicitationFilters = createSelector(getFilters, (filters) => {
  const { agencies = [], statuses = [], phases = [], dates } = filters
  const { startDate = null, endDate = null } = dates

  const filterOptions = [
    { filter: phases, field: 'phase' },
    { filter: agencies, field: 'agency_abbr' },
    { filter: statuses, field: 'current_status' },
  ]

  const dateFilter = { close_date: {} }
  if (startDate) {
    const date = startDate.format().split('T')[0] + 'T00:00:00Z'
    dateFilter.close_date = { $gte: date }
  }
  if (endDate) {
    const date = endDate.format().split('T')[0] + 'T00:00:00Z'
    dateFilter.close_date = {
      ...dateFilter.close_date,
      $lte: date,
    }
  }

  const filterItems = [
    ...filterOptions
      .filter(({ filter }) => filter.length)
      .map(({ filter, field }) => ({ [field]: { $in: filter } })),
    ...(startDate || endDate ? [dateFilter] : []),
  ]

  const filter = filterItems.length ? { $and: filterItems } : {}

  return filter
})

export const GrantsSelector = {
  getGrants,
  getGrantById,
  getSubmitting,
  getSelectedAgencies,
  getSelectedPhases,
  getSelectedStatuses,
  getStartDate,
  getEndDate,
  getPaging,
  makeGrants,
  makeGrant,
  makeGrantsCount,
  makeSolicitationFilters,
  error,
}
