import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { SubscriptionApi } from '../../../services'
import { StripeCheckoutError, StripeCheckoutSuccess } from '../StripeCheckout/subComponents'

//TODO:PageLayout Incomplete
export const StripePaymentRedirect = () => {
  const [status, setStatus] = useState('')

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get('session_id')

    const checkSessionStatus = async () => {
      try {
        const res = await SubscriptionApi.fetchStripeCheckoutSessionStatus({
          params: {
            sessionId,
          },
        })
        const data = (res as AxiosResponse<any, any>).data
        setStatus(data.status)
      } catch (error) {
        setStatus('error')
      }
    }
    checkSessionStatus()
  }, [])

  if (status === 'open') {
    return <Navigate to="/subscription" />
  }

  if (status === 'complete') {
    return <StripeCheckoutSuccess />
  }

  if (status === 'error') {
    return <StripeCheckoutError />
  }
  return null
}
