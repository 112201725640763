import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useSelector } from 'react-redux'
import { BarChart } from '../../../../../components/BarChart'
import { MatricesSelector } from '../../../../../store/TechnologyMatrices'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement, BarController)

export const TechnologyMetricesBar = () => {
  const agencies = useSelector(MatricesSelector.selectAgencies)
  const agencyNames = Object.keys(agencies)
  const agencyCounts = Object.values(agencies)

  const isEmpty = agencyCounts.every((count) => count === 0)

  const data = {
    labels: isEmpty ? ['agency'] : agencyNames,
    datasets: [
      {
        label: 'Number of Grants',
        backgroundColor: isEmpty ? '#F1F5F9' : '#00A94F',
        data: isEmpty ? [1] : agencyCounts,
        barThickness: 50,
        maxBarThickness: 40,
      },
    ],
  }

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 40,
          boxWidth: 20,
          font: {
            size: 8,
            weight: 'bold',
            lineHeight: 1.2,
          },
          generateLabels: (chart: any) => {
            const data = chart.data
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label: any, index: any) => {
                const dataset = data.datasets[0]
                const backgroundColor = dataset.backgroundColor as string
                return {
                  text: isEmpty ? `${label}` : `${label} - ${dataset.data[index]}`,
                  fillStyle: backgroundColor,
                  strokeStyle: backgroundColor,
                  lineWidth: 1,
                  hidden: false,
                  index: index,
                }
              })
            }
            return []
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 10,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  return <BarChart data={data} options={options} title="Total grants" />
}
