import { CopyAllOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Stack, SxProps, Typography, useTheme } from '@mui/material'
import DOMPurify from 'dompurify'
import moment from 'moment'
import { FC, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  AgencyIcon,
  DeadlineIcon,
  SolicitationTitleIcon,
  TopicNumberIcon,
  ViewIcon,
} from '../../components/icons'
import { AppDispatch, GrantsActions } from '../../store'
import { AgenciesSelectors } from '../../store/agencies'
import { AuthSelector } from '../../store/auth'
import { SnackbarActions } from '../../store/snackbar'
import { convertPhase } from '../../utils'
import {
  AgencyTag,
  FundingAmountForm,
  GrantAttributesCard,
  GrantDescription,
  LikeDislikeWidget,
  PhaseTag,
} from './subcomponents'

interface GrantDetailProps {
  grant?: any
  technologyModalHandler?: ReactNode
}

const btnStyles: SxProps = {
  flexGrow: 1,
  flexBasis: 0,
  minWidth: 200,
  padding: '0.9rem 0',
  borderRadius: '8px',
  textTransform: 'none',
}

const copyBtnStyles: SxProps = {
  flexGrow: 1,
  flexBasis: 0,
  minWidth: 200,
  padding: '0.9rem 0',
  borderRadius: '8px',
  textTransform: 'none',
}

export const GrantDetail: FC<GrantDetailProps> = ({ grant, technologyModalHandler }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()

  const isLoggedIn = useSelector(AuthSelector.hasToken)
  const defaultAgency = useSelector(AgenciesSelectors.getDefaultAgency)

  const {
    _id: grantId = '',
    matchedGrantId = '',
    fundingAmount = '',
    like = 0,
    type = '',
    branch = '',
    topicId = '',
    topic_title = '',
    solicitation = {},
    subtopic_title = '',
    topic_description = '',
    solicitation_number = '',
    additional_information = {},
    description: _description = '',
    agency = defaultAgency,
  } = (grant as any) || ({} as any)

  const phaseValue = convertPhase(solicitation.phase || 'N/A')

  const { close_date = '' } = solicitation

  const formattedCloseDate = close_date ? moment.utc(close_date).format('MM/DD/YYYY') : ''

  const formatDescription = () => {
    const description =
      _description.length < 12 && type === 'subtopic' ? topic_description : _description

    if (typeof description === 'string' && additional_information) {
      const formatedDescription = Object.keys(additional_information).reduce((acc, key) => {
        const regex = new RegExp(`${key}:`, 'g')
        return acc.replace(regex, `<b>${key}:</b>`)
      }, description)

      return DOMPurify.sanitize(formatedDescription)
    }

    return description
  }

  const generateGrantLink = async (grantId: string) => {
    try {
      const resultAction = await dispatch(GrantsActions.createShareableGrantAsync({ grantId }))

      if (GrantsActions.createShareableGrantAsync.rejected.match(resultAction)) {
        const errorMessage = resultAction.payload as any
        throw new Error(errorMessage)
      }

      const data = resultAction.payload as string

      if (!data) {
        throw new Error('unable to generate grant link')
      }

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getGrantLinkFromAddressbar = () => {
    return `${window.location.origin}${location.pathname}${location.search}`
  }

  const handleCopyGrantLink = async () => {
    try {
      if (!grantId) {
        throw new Error('Grant ID is required to create a shareable URL')
      }

      let link = ''
      if (isLoggedIn) {
        link = await generateGrantLink(grantId)
      } else {
        link = getGrantLinkFromAddressbar()
      }

      await navigator.clipboard.writeText(link)

      dispatch(
        SnackbarActions.showSnackbar({
          message: 'Grant link copied!',
          options: { variant: 'success' },
        }),
      )
    } catch (error: any) {
      dispatch(
        SnackbarActions.showSnackbar({
          message: error.message,
        }),
      )
    }
  }

  return (
    <Stack gap={3} p={2} width="100vw" maxWidth="100%">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          fontWeight={theme.typography.fontWeightMedium}
          fontSize="1.5rem"
          color="secondary"
        >
          {topic_title} {subtopic_title && `- ${subtopic_title}`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <AgencyTag
            value={agency.acronym}
            textColor={agency.textColor}
            backgroundColor={agency.backgroundColor}
            chipStyles={{ padding: 1, marginLeft: '1rem' }}
          />

          <PhaseTag value={phaseValue} tagStyles={{ marginLeft: '0.5rem' }} />
        </Box>
      </Box>

      {matchedGrantId && (
        <Grid container>
          <Grid item sm={6} xs={12}>
            <FundingAmountForm fundingAmount={fundingAmount} matchedGrantId={matchedGrantId} />
          </Grid>
        </Grid>
      )}

      <Stack
        display="flex"
        sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Stack direction="row" gap={2} flexWrap="wrap">
          <GrantAttributesCard
            label="Agency"
            icon={<AgencyIcon fontSize="large" />}
            descriptions={[agency.title, branch]}
          />

          <GrantAttributesCard
            label="Solicitation"
            icon={<SolicitationTitleIcon fontSize="large" />}
            descriptions={[solicitation_number]}
          />

          <GrantAttributesCard
            label="Topic Number"
            icon={<TopicNumberIcon fontSize="large" />}
            descriptions={[topicId.split('__').join(' : ')]}
          />

          <GrantAttributesCard
            label="Deadline"
            icon={<DeadlineIcon fontSize="large" />}
            descriptions={[formattedCloseDate]}
          />
        </Stack>

        <Stack direction="row" flexWrap="wrap" gap={3} mt={2} mb={{ xs: 2 }}>
          {isLoggedIn && (
            <>
              {technologyModalHandler ? (
                technologyModalHandler
              ) : (
                <LikeDislikeWidget like={like} refId={matchedGrantId} />
              )}
            </>
          )}

          <Button
            fullWidth
            color="primary"
            variant="contained"
            disableElevation={true}
            startIcon={<CopyAllOutlined />}
            sx={copyBtnStyles}
            onClick={handleCopyGrantLink}
          >
            Copy grant link
          </Button>

          <Button
            fullWidth
            color="primary"
            variant="outlined"
            disableElevation={true}
            startIcon={<ViewIcon />}
            onClick={() => window.open(`${solicitation.agency_url}`, '_blank')}
            sx={{
              ...btnStyles,
              background: 'white',
              maxWidth: !isLoggedIn ? '50%' : '100%',
            }}
          >
            View Agency Site
          </Button>
        </Stack>
      </Stack>

      <GrantDescription description={formatDescription()} />
    </Stack>
  )
}
