import { AxiosAPI } from './AxiosApi'

class TechnologiesApi extends AxiosAPI {
  /*fetch requests*/
  public fetchUserTechnologies = async (userId: string, options = {}) => {
    return this.GET(this.apiSchema.GET.users.technologies(userId), options)
  }
  public fetchTechnology = async (id: string, options = {}) => {
    return this.GET(this.apiSchema.GET.technologies.technology(id), options)
  }
  public fetchMatchedGrants = async (id: string, options = {}) => {
    return this.GET(this.apiSchema.GET.technologies.grants(id), options)
  }
  public fetchMatchedGrant = async (id: string, refId: string, options = {}) => {
    return this.GET(this.apiSchema.GET.technologies.grant(id, refId), options)
  }
  public fetchTechnologyMatrices = async (id: string, options = {}) => {
    return this.GET(this.apiSchema.GET.technologies.technologyMatrices(id), options)
  }
  public fetchFilteredtechGrants = async (technologyId: string, options = {}) => {
    return this.GET(this.apiSchema.GET.technologies.grants(technologyId), options)
  }
  /*end fetch requests*/

  /*create requests*/
  public createTechnology = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.technology.addtechnology(), body, options)
  }
  public createTechnologyGrant = async (techId: string, body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.technology.addGrant(techId), body, options)
  }
  public createTechnologyMembers = async (techId: string, body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.technology.addMember(techId), body, options)
  }

  public updateTechnologyMatchedGrant = async (
    { techId, refId }: { techId: string; refId: string },
    body: any,
    options = {},
  ) => {
    return this.PUT(this.apiSchema.PUT.technology.technologyGrants(techId, refId), body, options)
  }

  public updateTechnologies = async (techId: string, body: any, options = {}) => {
    return this.PUT(this.apiSchema.PUT.technology.updateTechnology(techId), body, options)
  }

  public deleteTechnology = async (techId: string, options = {}) => {
    return this.DELETE(this.apiSchema.DELETE.technologies.technology(techId), options)
  }
}

export default new TechnologiesApi()
