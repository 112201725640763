import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../store'
import {
  CreateTechnologyActions,
  CreateTechnologySelectors,
  CreateTechStages,
} from '../../store/createTechnologyForm'
import { SnackbarActions } from '../../store/snackbar'
import { TechnologiesActions, TechnologiesSelectors } from '../../store/technologies'
import { TechnologyForm } from './stages'
import { KeywordsForm } from './stages/KeywordsForm'
import {
  AddTechnologyBreadcrumbs,
  BackToTechnologiesButton,
  LoadingGrantSearchScreen,
  LoadingScreen,
} from './subComponents'

export const AddTechnology = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const stage = useSelector(CreateTechnologySelectors.getStage)
  const technology = useSelector(CreateTechnologySelectors.getTechnology)
  const keywords = useSelector(CreateTechnologySelectors.makeKeywords)
  const selectedKeywords = useSelector(CreateTechnologySelectors.makeSelectedKeywords)

  const technologyError = useSelector(TechnologiesSelectors.error)
  const createTechnologyError = useSelector(CreateTechnologySelectors.error)

  useEffect(() => {
    if (technologyError || createTechnologyError) {
      const message = technologyError || createTechnologyError
      dispatch(SnackbarActions.showSnackbar({ message }))
    }

    return () => {
      dispatch(TechnologiesActions.resetTechnologyError())
      dispatch(CreateTechnologyActions.resetError())
    }
  }, [technologyError, createTechnologyError])

  const handleSubmitTechnology = async ({
    title,
    description,
  }: {
    title: string
    description: string
  }) => {
    dispatch(CreateTechnologyActions.setTechnologyValues({ title, description }))
    dispatch(CreateTechnologyActions.incrementStage())
    const fetchKeywordActions = await dispatch(
      CreateTechnologyActions.fetchKeywordsAsync({ query: description }),
    )
    if (CreateTechnologyActions.fetchKeywordsAsync.fulfilled.match(fetchKeywordActions)) {
      dispatch(CreateTechnologyActions.incrementStage())
    }

    if (CreateTechnologyActions.fetchKeywordsAsync.rejected.match(fetchKeywordActions)) {
      dispatch(CreateTechnologyActions.decrementStage())
      dispatch(CreateTechnologyActions.updateStageStatus({ stage: 0, status: false }))
    }
  }

  const handleSubmitKeywords = async () => {
    handleCreateTechnology()
  }

  const handleCreateTechnology = async () => {
    dispatch(CreateTechnologyActions.incrementStage())
    const createTechRequest = await dispatch(
      TechnologiesActions.addTechnologyAsync({
        ...technology,
        keywords,
        selectedKeywords,
      }),
    )
    if (TechnologiesActions.addTechnologyAsync.fulfilled.match(createTechRequest)) {
      handleCreateMatches({ technology: createTechRequest, keywords: selectedKeywords })
    }
    if (TechnologiesActions.addTechnologyAsync.rejected.match(createTechRequest)) {
      dispatch(CreateTechnologyActions.decrementStage())
      dispatch(CreateTechnologyActions.updateStageStatus({ stage: 2, status: false }))
    }
  }

  const handleCreateMatches = async ({
    technology,
    keywords,
  }: {
    technology: any
    keywords: string[]
  }) => {
    const createMatchesRequest = await dispatch(
      CreateTechnologyActions.createMatchedGrantsAsync({
        _id: technology.payload._id,
        query: technology.payload.description,
        keywords,
      }),
    )

    if (CreateTechnologyActions.createMatchedGrantsAsync.rejected.match(createMatchesRequest)) {
      const errorMessage = `we have received an error from our back end server.
                            Don't worry, your technology is saved and we will
                            update it as soon as we can resolve the issue`
      dispatch(
        SnackbarActions.showSnackbar({
          message: errorMessage,
          options: { autoHideDuration: 5000 },
        }),
      )
    }

    dispatch(CreateTechnologyActions.resetState())
    navigate(`/technology/${technology.payload._id}/grants`)
  }

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item md={8} xs={12}>
        {stage % 2 !== 1 && (
          <>
            <BackToTechnologiesButton />
            <AddTechnologyBreadcrumbs />
          </>
        )}
        {stage === CreateTechStages.technology && (
          <TechnologyForm action={handleSubmitTechnology} />
        )}
        {stage === CreateTechStages.pendingKeywords && (
          <LoadingScreen message="Give us moment while we match your technology to different grant topics." />
        )}
        {stage === CreateTechStages.keywords && <KeywordsForm action={handleSubmitKeywords} />}

        {stage === CreateTechStages.pendingResults && (
          <LoadingGrantSearchScreen message="Finding the perfect grants. Please wait..." />
        )}
      </Grid>
    </Grid>
  )
}
