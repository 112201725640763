import { Box, Stack, Typography, useTheme } from '@mui/material'
import Lottie from 'lottie-react'
import { FC } from 'react'
import SearchGrantLoader from '../../../components/icons/rocket-loader.json'

interface LoadingGrantSearchScreenProps {
  message: string
}

export const LoadingGrantSearchScreen: FC<LoadingGrantSearchScreenProps> = ({ message }) => {
  const theme = useTheme()
  return (
    <Stack alignItems="center" justifyContent="center" gap={2} overflow="hidden">
      <Box maxHeight={220} maxWidth={220} marginBottom="100px">
        <Lottie animationData={SearchGrantLoader} loop autoplay />
      </Box>
      <Typography
        fontSize="1.75rem"
        fontWeight={theme.typography.fontWeightMedium}
        color="#0A1420"
        textAlign="center"
      >
        {message}
      </Typography>
    </Stack>
  )
}
