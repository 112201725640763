import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../../../../../store'
import { GrantMatchActions } from '../../../../../store/grantMatcher'
import { TechGrantsActions } from '../../../../../store/technology'
import { KanbanViewColumns } from './KanbanViewColumns'

interface KanbanViewProps {
  cb: Function
  handleSlider: Function
}

export const KanbanView: FC<KanbanViewProps> = ({ cb, handleSlider }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { id: techId = '' } = useParams()

  const handleDragEnd = async (event: DragEndEvent) => {
    try {
      const { active, over, delta } = event

      if (!active || !over) return

      //handle slider click
      const { x, y } = delta
      const { topicId = '' } = active.data.current as any

      if (x === 0 && y === 0 && topicId) {
        handleSlider(topicId)
        return
      }

      //handle drag & drop
      const { id: refId } = active
      const { id: newProgressStatus } = over

      if (!refId) return

      dispatch(
        TechGrantsActions.setProgressStatus({
          matchedGrantId: refId,
          progressStatus: newProgressStatus,
        }),
      )

      await dispatch(
        GrantMatchActions.updateMatchedTechGrantAsync({
          params: { techId, refId: refId as string },
          body: { progressStatus: newProgressStatus },
        }),
      )

      cb()
    } catch (error) {
      cb()
    }
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <KanbanViewColumns />
    </DndContext>
  )
}
