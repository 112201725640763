import { Box, Grid, InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { ChangeEvent, FormEvent, FormEventHandler, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchIcon } from '../../../../components/icons'
import { AppDispatch, GrantsActions, GrantsSelector, RootState } from '../../../../store'
import { FormsActions, FormsSelector } from '../../../../store/forms'
import { AgencyFilter, DateFilter, PhaseFilter, StatusFilter } from './SearchFilters'

export const SearchForm = () => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const selectedAgencies = useSelector(GrantsSelector.getSelectedAgencies)
  const selectedPhases = useSelector(GrantsSelector.getSelectedPhases)
  const selectedStatuses = useSelector(GrantsSelector.getSelectedStatuses)
  const startDate = useSelector(GrantsSelector.getStartDate)
  const endDate = useSelector(GrantsSelector.getEndDate)

  const paging = useSelector(GrantsSelector.getPaging)

  const fetchingGrants = useSelector(GrantsSelector.getSubmitting)

  const { limit, skip } = paging

  const formId = 'grantQuery'
  const formValues = useSelector((state: RootState) => FormsSelector.getFormValues(state, formId))

  const { technology = '' } = formValues

  const applyFilters = (filter: any) => {
    const obj = {
      ...filter,
      selectedAgencies,
      selectedPhases,
      selectedStatuses,
      startDate,
      endDate,
    }
    dispatch(GrantsActions.setFilters({ ...obj }))
  }

  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (!technology) {
        dispatch(GrantsActions.resetGrants())
      }

      if (technology && technology.length >= 3) {
        const options = {
          params: {
            query: technology,
            $limit: limit,
            $skip: skip,
            $sort: 'score desc',
          },
        }

        const isoStartDate = startDate && startDate.format().split('T')[0] + 'T00:00:00Z'
        const isoEndDate = endDate && endDate.format().split('T')[0] + 'T00:00:00Z'

        const body = {
          agencies: selectedAgencies,
          phases: selectedPhases,
          statuses: selectedStatuses,
          startDate: isoStartDate,
          endDate: isoEndDate,
        }

        dispatch(GrantsActions.fetchQuickSearchGrantsAsync({ body, options }))
      }
    }, 800)

    return () => {
      clearTimeout(debounceId)
    }
  }, [
    dispatch,
    skip,
    limit,
    endDate,
    startDate,
    technology,
    selectedPhases,
    selectedStatuses,
    selectedAgencies,
  ])

  //TODO: these callbacks should be removed and they can be completly handled from redux
  const handleStartDateChange = (newStartDate: any) => {
    if (!newStartDate && !endDate) {
      return
    }
    applyFilters({
      dates: {
        startDate: newStartDate,
        endDate: newStartDate ? endDate : null,
      },
    })
  }

  const handleEndDateChange = (newEndDate: any) => {
    if (!newEndDate && !startDate) {
      return
    }
    applyFilters({
      dates: {
        startDate: newEndDate ? startDate : null,
        endDate: newEndDate,
      },
    })
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent) => {
    event.preventDefault()
    dispatch(GrantsActions.fetchQuickSearchGrantsAsync(technology))
  }

  const updateValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target
    dispatch(FormsActions.setValue({ id: formId, [name]: value }))
    dispatch(GrantsActions.resetPaging())
  }

  return (
    <Grid container>
      <Grid item md={9} xs={12}>
        <Typography
          fontSize="1.5rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary"
          mb={1}
        >
          Browse for grants
        </Typography>
        <Typography
          fontSize="1rem"
          fontWeight={theme.typography.fontWeightMedium}
          color="secondary.light"
        >
          Use the quick search to find grants by phase, agency, and keyword to quickly add them to
          your technologies.
        </Typography>

        <Box mt={5} component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
          <TextField
            fullWidth
            name="technology"
            variant="standard"
            value={technology}
            placeholder="Browse for grant"
            onChange={updateValue}
            disabled={fetchingGrants}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                pb: 2,
                fontWeight: 500,
                fontSize: '1.3rem',
                color: theme.palette.secondary.main,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} display="flex" gap="15px" alignItems="center" flexWrap="wrap" my={2.5}>
        <AgencyFilter selectedAgencies={selectedAgencies} />
        <PhaseFilter
          handlePhaseChange={(phases: any) => applyFilters({ phases: phases })}
          selectedPhases={selectedPhases}
        />
        <StatusFilter
          handleStatusChange={(statuses: any) => applyFilters({ statuses: statuses })}
          selectedStatuses={selectedStatuses}
        />
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={handleStartDateChange}
          setEndDate={handleEndDateChange}
        />
      </Grid>
    </Grid>
  )
}
